import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

export default function Custom404() {
  return (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        background: 'center/cover no-repeat url(/brand/nav-bg.png)'
      }}
    >
      <MDBox
        sx={(theme) => ({
          borderRight: `1px solid ${theme.borders.borderColor}`,
          padding: '10px 23px 10px 0',
          mr: '20px'
        })}
      >
        <MDTypography variant="h2">404</MDTypography>
      </MDBox>
      <MDBox
        sx={(theme) => ({
          position: 'relative',
          '& svg': {
            right: '-50%',
            position: 'absolute',
            top: '-5rem'
          },
          '& #leaves': {
            fill: theme.palette.grey[600]
          }
        })}
      >
        <MDTypography variant="body2">This page could not be found</MDTypography>
        <svg width="225px" height="64px" viewBox="0 0 225 64">
          <path
            d="M224.699352,61.4956522 C220.629047,62.6086957 216.373728,63.1652174 212.025902,63.1652174 C200.832562,63.1652174 190.009251,59.084058 181.498612,51.7565217 C179.46346,49.9942029 177.613321,48.0463768 175.948196,46.0057971 C175.855689,46.0057971 175.855689,45.9130435 175.855689,45.8202899 L175.948196,45.8202899 C193.894542,40.7188406 213.043478,46.9333333 224.699352,61.4956522 Z M220.351526,5.7411853e-13 C215.911193,27.3623188 198.149861,40.7188406 175.948196,43.0376812 C179.555967,19.2927536 194.819611,3.15362319 220.351526,5.7411853e-13 Z M167.807586,1.39130435 C173.080481,9.08985507 175.855689,18.2724638 175.855689,27.5478261 C175.855689,32.6492754 175.023127,37.7507246 173.358002,42.6666667 C165.124884,30.515942 163.089732,15.3043478 167.807586,1.39130435 Z"
            id="leaves"
            fill="#0FDBA8"
          />
        </svg>
      </MDBox>
    </MDBox>
  );
}
